import {ModalContext} from "../../../contexts"
import React, {useContext, useState} from "react"
import {Button, Form, Input, Label} from "reactstrap"
import Select from "react-select"
import {createConfig} from "../../../api/settings"
import {toast} from "react-toastify"

const AddEmailConfig = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [transportOptions, setTransportOptions] = useState([
    {value: 'smtp', label: 'SMTP'},
  ])
  const [encryptionOptions, setEncryptionOptions] = useState([
    {value: 'tsl', label: 'TSL'},
  ])

  const submitHandler = (e) => {
    e.preventDefault()

    let data = new FormData(e.target)

    createConfig(data).then(r => {
      closeModal()

      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Input name="type" value="email" type="hidden" />
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Config name</Label>
          <Input
            id="email_config_name"
            name="name"
            placeholder="Enter config name"
            required={true}
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Transport</Label>
          <Select
            id="email_config_transport"
            name="transport"
            placeholder="Select..."
            autoComplete="off"
            options={transportOptions}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Encryption</Label>
          <Select
            id="email_config_encrypt"
            name="encryption"
            placeholder="Select..."
            autoComplete="off"
            options={encryptionOptions}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Domain</Label>
          <Input
            id="email_config_domain"
            name="domain"
            placeholder="Enter domain"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Host</Label>
          <Input
            id="email_config_host"
            name="host"
            placeholder="Enter host"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Port</Label>
          <Input
            id="email_config_port"
            name="port"
            placeholder="Enter port"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Username</Label>
          <Input
            id="email_config_username"
            name="username"
            placeholder="Enter username"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Password</Label>
          <Input
            id="email_config_pass"
            name="password"
            placeholder="Enter password"
            autoComplete="off"
          />
        </div>

        <div className="hstack justify-content-end">
          <Button type="button" id="email_config_close_modal_button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" id="email_config_save_button" color="primary" className="btn">Save</Button>
        </div>

      </Form>
    </>
  )
}

export default AddEmailConfig
