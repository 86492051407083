import React from 'react';
import {Routes, Route, useLocation} from "react-router-dom";
import ErrorBoundary from "../pages/AuthenticationInner/Errors/ErrorBoundary";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

//routes
import {authProtectedRoutes, publicRoutes} from "./web_routes";
import {AuthProtected} from './AuthProtected';
import {ModalProvider} from "../contexts";

const Index = () => {
  const location = useLocation()

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <ErrorBoundary urlKey={location.pathname}>
                  <NonAuthLayout>
                    {route.component}
                  </NonAuthLayout>
                </ErrorBoundary>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <ErrorBoundary urlKey={location.pathname}>
                  <AuthProtected>
                    <ModalProvider>
                      <VerticalLayout>{route.component}</VerticalLayout>
                    </ModalProvider>
                  </AuthProtected>
                </ErrorBoundary>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
