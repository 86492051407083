import {Button, Form, Input, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {editDocument, getDocumentType} from "../../../api/settings"
import {toast} from "react-toastify"
import {selectListData} from "../../../api/general"
import Select from "react-select"

const EditDocument = ({type, id, onSave, isHidden}) => {
  const {closeModal} = useContext(ModalContext)
  const [documentsType, setDocumentsType] = useState(type)
  const [checked, setChecked] = useState(isHidden)
  const [documentCategoriesOptions, setDocumentCategoriesOptions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')

  const documentChangeHandler = (e) => {
    setDocumentsType(e.target.value)
  }

  const getCategoriesData = () => {
    getDocumentType(id).then(r => {
      setSelectedCategory(documentCategoriesOptions.find(i => i.value === r.category_id))
    })
  }

  const getDocumentCategoriesData = () => {
    selectListData('document_categories').then(r => {
      setDocumentCategoriesOptions(r)
    })
  }

  const saveHandler = (e) => {
    e.preventDefault()

    const data = {
      document_type_id: id,
      hidden_from_customer: checked,
      type: documentsType,
      document_category_id: selectedCategory.value,
    }

    editDocument(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        onSave()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  useEffect(() => {
    getCategoriesData()
  }, [documentCategoriesOptions])

  useEffect(() => {
    getDocumentCategoriesData()
  }, [])

  return (
    <>
      <Form onSubmit={saveHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Type name</Label>
          <Input
            id="doc_type_name"
            placeholder='Address'
            autoComplete="off"
            value={documentsType}
            onChange={documentChangeHandler}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Document Category</Label>
          <Select
            id="doc_type_category_select"
            name="document_category_id"
            value={selectedCategory}
            options={documentCategoriesOptions}
            isClearable={true}
            onChange={selected => setSelectedCategory(selected)}
          />
        </div>
        <div className="form-check form-switch form-switch-left form-switch-sm mb-3">
          <Input
            className="form-check-input"
            type="checkbox"
            id="doc_type_hidden_checkbox"
            checked={checked}
            onChange={e => {
              setChecked(e.target.checked)
            }}
          />
          <Label className="form-check-label text-muted" for="hidden_from_customer">
            Hidden from customer
          </Label>
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" id="doc_type_close_modal_button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" id="doc_type_save_button" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default EditDocument
