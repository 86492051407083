import React, { Component } from 'react'
import {Link} from "react-router-dom"

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorData: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorData: error })
  }

  componentDidUpdate(prevProps) {
    if (this.props.urlKey !== prevProps.urlKey) {
      this.setState({ hasError: false, errorData: null })
    }
  }

  render() {
    if (this.state.errorData) {
      return (
        <h3 className='w-100 text-center bg-light-danger py-5 text-danger'>
          Oops... Something went wrong...
          <p className='fw-normal pt-2 px-10 fs-20'>
            {this.state?.errorData?.message
              ? `Error: "${this.state?.errorData?.message}"`
              : 'Some error without information...'
            }
          </p>
          <p className="fw-normal text-gray-600 fs-16">
            Please, try reloading the page. If the issue persists, contact support.
          </p>
          <div className='mt-3'>
            <button
              className="btn fw-bold btn-warning me-2"
              onClick={() => window.location.reload()}
            >
              Reload Page
            </button>
            <Link
              to={'/dashboard'}
              className="btn fw-bold btn-warning ms-2"
            >
              Go to Dashboard
            </Link>
          </div>
        </h3>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
