import {Button, Col, Form, Input, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {editBrand} from "../../../api/settings"
import {toast} from "react-toastify"
import Select from "react-select"
import {selectListData} from "../../../api/general"
import {Tooltip} from "react-tooltip";

const EditBrand = (props) => {
  const {
    id,
    shortName,
    title,
    address,
    logoPath,
    updateTable,
    emailLogoPath,
    aspectRatio,
    departments,
    emailId,
    sendFromEmployee,
    emailTemaplte,
    disableUnsubscribeLink
  } = props
  const {closeModal} = useContext(ModalContext)
  const [brandName, setBrandName] = useState(shortName)
  const [brandTitle, setBrandTitle] = useState(title)
  const [brandAddress, setBrandAddress] = useState(address)
  const [brandLogo, setBrandLogo] = useState('')
  const [emailConfigOptions, setEmailConfigOptions] = useState([])
  const [selectedEmailConfig ,setSelectedEmailConfig] = useState('')
  const [emailLogosPath, setEmailLogosPath] = useState(emailLogoPath)
  const [departmentsOptions, setDepartmentsOptions] = useState([])
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [sendFromCheckbox, setSendFromCheckbox] = useState(sendFromEmployee)
  const [disableUnsubscribeLinkCheckBox, setDisableUnsubscribeLinkCheckBox] = useState(disableUnsubscribeLink)
  const [aspectRatioOptions, setAspectRatioOptions] = useState([
    {value: 51, label: '5:1'},
    {value: 41, label: '4:1'},
    {value: 31, label: '3:1'},
    {value: 21, label: '2:1'},
    {value: 32, label: '3:2'},
    {value: 11, label: '1:1'},
  ])
  const [selectedAspectRatio, setSelectedAspectRatio] = useState([])
  const [emailTemplatesOptions, setEmailTemplatesOptions] = useState()
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState([])

  const unsubscribeTooltipeText = 'Please note: the absence of such a link may increase the risk of ' +
    'your emails being blocked by spam services, as it may raise suspicions of spam activity.'

  const submitHandler = e => {
    e.preventDefault()

    let data = new FormData(e.target)
    data.append('brand_id', id)

    editBrand(data).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(r?.message || 'Empty response message.')
        updateTable()
        closeModal()
      } else {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        } else {
          toast.error(message)
        }
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  useEffect(() => {
    selectListData('departments').then(r => setDepartmentsOptions(r))
    selectListData('emails_config').then(r => setEmailConfigOptions(r))
    selectListData('emails_template').then(r => setEmailTemplatesOptions(r))
  }, [])

  useEffect(() => {
    if (sendFromEmployee === "on") {
      setSendFromCheckbox(true)
    } else {
      setSendFromCheckbox(false)
    }
  }, [])

  useEffect(() => {
    if (departmentsOptions?.length && departments?.length) {
      setSelectedDepartments(departmentsOptions.filter(option => option.value === departments.find(i => i.id === option.value)?.id))
    }

    setSelectedAspectRatio(aspectRatioOptions.find(option => Number(option.value) === Number(aspectRatio)))

    if (emailConfigOptions?.length) {
      setSelectedEmailConfig(emailConfigOptions.find(option => Number(option.value) === Number(emailId)))
    }

    if (emailTemaplte?.length && emailTemplatesOptions) {
      setSelectedEmailTemplate(emailTemplatesOptions.find(option => option.value === emailTemaplte))
    }
  }, [departmentsOptions, emailConfigOptions, emailTemplatesOptions])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Short Name</Label>
          <Input
            id="edit_brand_"
            name="short_name"
            placeholder="Short name"
            required={true}
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            autoComplete="off"
            disabled={true}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Title</Label>
          <Input
            id="edit_brand_title"
            name="title"
            placeholder="Title"
            required={true}
            value={brandTitle}
            onChange={(e) => setBrandTitle(e.target.value)}
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Address</Label>
          <Input
            id="edit_brand_address"
            type="textarea"
            rows="5"
            name="address"
            value={brandAddress ?? ''}
            onChange={(e) => setBrandAddress(e.target.value)}
            placeholder="Address..."
          />
        </div>
        <div className="form-group mb-3">
          <div className="flex-shrink-0 text-center">
            <img alt="Empty Logo" src={import.meta.env.VITE_APP_URL + '/storage/' + logoPath} width="200px"/>
          </div>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Logo</Label>
          <Input
            id="edit_brand_logo"
            type="file"
            name="logo_file"
            value={brandLogo}
            onChange={(e) => setBrandLogo(e.target.value)}
            placeholder="Add logo..."
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Email logo URL</Label>
          <Input
            id="edit_brand_email_logo"
            type="input"
            name="email_logo_url"
            value={emailLogosPath ?? ''}
            onChange={(e) => setEmailLogosPath(e.target.value)}
            placeholder="Add logo URL..."
          />
        </div>
        <div className="form-group mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Mail logo aspect ratio</Label>
          <Select
            id="edit_brand_email_aspect"
            placeholder="Choose..."
            name="aspect_ratio"
            value={selectedAspectRatio}
            onChange={(selected) => setSelectedAspectRatio(selected)}
            options={aspectRatioOptions}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Email config</Label>
          <Select
            id="edit_brand_email_config"
            placeholder="Choose..."
            required={true}
            name="config_id"
            value={selectedEmailConfig}
            onChange={(option) => setSelectedEmailConfig(option)}
            options={emailConfigOptions}
          />
        </div>
        <div className="mb-3 d-flex align-items-end justify-content-between">
          <Col className="form-group">
            <Label className="form-label mb-0">Email template</Label>
            <Select
              id="edit_brand_email_template"
              placeholder="Choose..."
              required={true}
              name="email_template"
              value={selectedEmailTemplate}
              onChange={(option) => setSelectedEmailTemplate(option)}
              options={emailTemplatesOptions}
            />
          </Col>
          <div className="ms-3">
            <a
              target="_blank"
              className="btn btn-primary waves-effect waves-light"
              href={selectedEmailTemplate.preview_url}
              role="button"
              id="edit_brand_preview"
            >
              Preview
            </a>
          </div>
        </div>
        <div className="form-group mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Departments</Label>
          <Select
            id="edit_brand_department"
            placeholder="Choose..."
            isClearable={true}
            isMulti={true}
            required={true}
            name="department_ids[]"
            value={selectedDepartments}
            onChange={(selected) => setSelectedDepartments(selected)}
            options={departmentsOptions}
          />
        </div>

        <div className='d-flex mt-3 mb-3'>
          <div className="form-check form-switch form-switch-left form-switch-sm">
            <Input
              id="edit_brand_send_from_checkbox"
              className="form-check-input"
              checked={sendFromCheckbox ?? ''}
              name="send_from_employee"
              onChange={e => {
                setSendFromCheckbox(e.target.checked)
              }}
              type="checkbox"
            />
            <Label className="form-check-label text-muted" for="edit_brand_send_from_checkbox">
              Send from employee
            </Label>
          </div>

          <div className="form-check form-switch form-switch-left form-switch-sm ms-3">
            <Input
              id="edit_brand_unsubscribe_button"
              className="form-check-input"
              checked={disableUnsubscribeLinkCheckBox ?? ''}
              name="disable_unsubscribe_link"
              onChange={e => {
                setDisableUnsubscribeLinkCheckBox(e.target.checked)
              }}
              type="checkbox"
            />
            <Label className="form-check-label text-muted" for="edit_brand_unsubscribe_button">
              Disabled unsubscribe link
            </Label>
            <span>
              <i
                className="ri-error-warning-line text-primary align-middle fs-20 ms-1 mt-0 pt-0"
                style={{cursor: 'help', lineHeight: "1"}}
                data-tooltip-id={'unsubscribe'}
                data-tooltip-content={unsubscribeTooltipeText}
              ></i>
              <Tooltip id={'unsubscribe'} style={{maxWidth: '300px'}}/>
            </span>
          </div>
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" id="edit_brand_close_modal_button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" id="edit_brand_save_button" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default EditBrand
