import {
  Accordion,
  AccordionItem,
  Button,
  Collapse,
  Form,
  CardBody,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from "reactstrap"
import {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import classnames from "classnames"
import {assetsData} from "../../../api/general";
import {editDepartment} from "../../../api/settings";
import {toast} from "react-toastify";

const EditDepartment = ({name, id, updateTable, settings}) => {
  const {closeModal} = useContext(ModalContext)
  const [assets, setAssets] = useState([])
  const [departmentName, setDepartmentName] = useState(name)
  const [plusiconCol1, setplusiconCol1] = useState(false)

  const getAssetData = () => {
    assetsData().then((r) => {
      setAssets(r)
    })
  }

  const departmentsNameChangeHandler = (e) => {
    setDepartmentName(e.target.value)
  }

  const t_plusiconCol1 = () => {
    setplusiconCol1(!plusiconCol1)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)

    formData.append('department_id', id)
    formData.append('name', departmentName)

    editDepartment(formData).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  useEffect(() => {
    getAssetData()
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Department name</Label>
          <Input
            placeholder='Department name'
            autoComplete="off"
            id="department_name"
            value={departmentName}
            onChange={departmentsNameChangeHandler}
          />
        </div>
        <CardBody className="mb-3">
          <Accordion className="custom-accordionwithicon-plus" id="accordionWithplusicon" open="" toggle={t_plusiconCol1}>
            <AccordionItem>
              <h2 className="accordion-header" id="accordionwithouticonExample1">
                <button
                  className={classnames("accordion-button", {collapsed: !plusiconCol1})}
                  type="button"
                  id="accordion_button"
                  onClick={t_plusiconCol1}
                  style={{cursor: "pointer"}}
                >
                  Asset addresses
                </button>
              </h2>
              <Collapse isOpen={plusiconCol1} className="accordion-collapse" id="accor_plusExamplecollapse1">
                <div className="accordion-body">
                  {assets.map((asset, idx) => (
                    <Row key={idx}>
                      <Col xs={3}>
                        <FormGroup>
                          <Input
                            className="text-primary"
                            placeholder='Asset...'
                            name={`asset_address[${asset.value}]`}
                            value={asset.value}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={9}>
                        <FormGroup>
                          <Input
                            placeholder='Address...'
                            id={`${asset.value}_address_input`}
                            defaultValue={(settings && settings.assets[asset.value]?.address) || ''}
                            name={`asset_address[${asset.value}][address]`}
                            onChange={e => e.target.value}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ))}
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>
        </CardBody>
        <div className="hstack justify-content-end">
          <Button type="button" id="close_ed_modal_button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" id="save_ed_button" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default EditDepartment
