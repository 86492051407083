import {Button, Table} from "reactstrap"
import {Link} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../contexts"
import AddBrand from "./Modals/AddBrand"
import EditBrand from "./Modals/EditBrand"
import {deleteBrand, getBrands} from "../../api/settings"
import {toast} from "react-toastify";
import {useUserCan} from "../../Components/Hooks/UserHooks"

const Brands = () => {
  const {openModal} = useContext(ModalContext)
  const [brands, setBrands] = useState([])

  // Permissions
  const canCreateBrand = useUserCan('create_brand')
  const canEditBrand = useUserCan('edit_brand')
  const canDeleteBrand = useUserCan('delete_brand')

  const getBrandsData = () => {
    getBrands().then(r => {
      setBrands(r)
    })
  }

  const updateBrand = () => {
    getBrandsData()
  }

  const addNewBrandHandler = () => {
    openModal({
      title: "Add Brand",
      content: <AddBrand updateTable={updateBrand}/>
    })
  }

  const deleteBrandHandler = id => {
    if (confirm("Are you sure to delete this brand?")) {
      deleteBrand(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getBrandsData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const editBrandHandler = (
    id,
    short_name,
    title,
    logo_path,
    address,
    email_logo_url,
    aspect_ratio,
    departments,
    config_id,
    send_from_employee,
    email_template,
    disable_unsubscribe_link
  ) => {
    openModal({
      title: "Edit Brand",
      content: <EditBrand
        id={id}
        shortName={short_name}
        title={title}
        logoPath={logo_path}
        address={address}
        aspectRatio={aspect_ratio}
        emailLogoPath={email_logo_url}
        emailId={config_id}
        departments={departments}
        sendFromEmployee={send_from_employee}
        emailTemaplte={email_template}
        disableUnsubscribeLink={disable_unsubscribe_link}
        updateTable={updateBrand}
      />
    })
  }

  useEffect(() => {
    getBrandsData()
  }, []);

  return (
    <>
      {canCreateBrand &&
      <div className="d-flex justify-content-end mt-1">
          <Button color="primary" id="add_brand_button" className="btn mb-3" onClick={addNewBrandHandler}>
            Add Brand
          </Button>
      </div>}
      <div className="table-responsive">
        <Table className="table-borderless table-nowrap">
          <thead>
            <tr className="border-bottom">
              <th className="col-1" scope="col">ID</th>
              <th className="col-2" scope="col">SHORT NAME</th>
              <th className="col-3" scope="col">TITLE</th>
              <th className="col-5" scope="col">ADDRESS</th>
              {(canEditBrand || canDeleteBrand) &&
              <th className="col-1" scope="col">ACTIONS</th>}
            </tr>
          </thead>
          <tbody>
          {brands.length > 0 ?
            brands.map((brand, idx) => {
              return (
                <tr key={idx}>
                  <td>{brand.id}</td>
                  <td>{brand.short_name ?? '-'}</td>
                  <td>{brand.title ?? '-'}</td>
                  <td>{brand.address ?? '-'}</td>
                  {(canEditBrand || canDeleteBrand) &&
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {canEditBrand &&
                      <Link
                        to="#"
                        id={`edit_brand_${idx + 1}`}
                        onClick={() => editBrandHandler(
                          brand.id,
                          brand.short_name,
                          brand.title,
                          brand.logo_path,
                          brand.address,
                          brand.email_logo_url,
                          brand.meta.aspect_ratio,
                          brand.departments,
                          brand.meta.config_id,
                          brand.meta.send_from_employee,
                          brand.meta.email_template,
                          brand.meta.disable_unsubscribe_link
                        )}
                        className="link-success fs-15"
                      >
                        <i className="ri-edit-2-line"></i>
                      </Link>}
                      {canDeleteBrand &&
                      <Link
                        to="#"
                        id={`delete_brand_${idx + 1}`}
                        onClick={() => deleteBrandHandler(brand.id)}
                        className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>}
                    </div>
                  </td>}
                </tr>
              )
            }) :
            (<tr>
              <td colSpan={5} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default Brands
