import {Button, Table} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../contexts"
import {Link} from "react-router-dom"
import {deleteDepartment, deleteTeam, getDepartments} from "../../api/settings"
import AddDepartment from "./Modals/AddDepartment"
import AddTeam from "./Modals/AddTeam"
import {toast} from "react-toastify"
import EditDepartment from "./Modals/EditDepartment"
import EditTeam from "./Modals/EditTeam"
import {prettyDate} from "../../helpers/utils"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import {selectListData} from "../../api/general"

const DepartmentsAndTeams = () => {
  const [departments, setDepartments] = useState([])
  const {openModal} = useContext(ModalContext)
  const [statusGroupsList ,setStatusGroupsList] = useState()

  // Permissions
  const canCreateDepartment = useUserCan('create_department')
  const canCreateTeam = useUserCan('create_team')
  const canViewTeams = useUserCan('view_teams')
  const canEditDepartment = useUserCan('edit_department')
  const canDeleteDepartment = useUserCan('delete_department')
  const canEditTeam = useUserCan('edit_team')
  const canDeleteTeam = useUserCan('delete_team')

  const getDepartmentsData = () => {
    getDepartments().then((r) => {
      setDepartments(r)
    })
  }

  const getCustomerStatusGroupsData = () => {
    selectListData('customer_status_groups').then(r => {
      setStatusGroupsList(r)
    })
  }

  const updateDepartment = (id, name) => {
    const updatedDepartments = departments.map((item) => {
      if (item.id === id) {
        return {...item, name}
      }
      return item
    })
    setDepartments(updatedDepartments)
    getDepartmentsData()
  }

  const updateTeam = (id, name) => {
    const updatedTeams = departments.map((item) => {
      if (item.id === id) {
        return {...item, name}
      }
      return item
    })
    setDepartments(updatedTeams)
    getDepartmentsData()
  }

  const editDepartmentAndTeamHandler = (id, type, name) => {
    if (type === 'Department') {
      openModal({
        title: "Edit Department",
        content: <EditDepartment id={id} name={name} updateTable={updateDepartment} settings={departments.find(i => i.id === id)?.settings?.settings}/>
      })
    } else {
      openModal({
        title: "Edit Team",
        content: <EditTeam id={id} name={name} updateTable={updateTeam}/>
      })
    }
  }

  const addDepartmentHandler = () => {
    openModal({
      title: "Add Department",
      content: <AddDepartment updateTable={getDepartmentsData}/>
    })
  }

  const addTeamHeaderHandler = () => {
    openModal({
      title: "Add Team",
      content: <AddTeam updateTable={getDepartmentsData}/>
    })
  }

  const deleteDepartmentAndTeamHandler = (id, type) => {
    if (type === 'Department') {
      if (confirm('Are you sure to delete department?')) {
        deleteDepartment(id).then((r) => {
          if (r.success) {
            toast.success(r.message)
            getDepartmentsData()
          } else {
            toast.error(r.message)
          }
        })
      }
    } else {
      if (confirm('Are you sure to delete team?')) {
        deleteTeam(id).then((r) => {
          if (r.success) {
            toast.success(r.message)
            getDepartmentsData()
          } else {
            toast.error(r.message)
          }
        })
      }
    }
  }

  const rowTemplate = (item, department_id, team_id, idx) => {
    let type = team_id ? 'Team' : 'Department'

    return (
      <tr className="border-bottom">
        <td>{department_id}{team_id ? '.' + team_id : ''}</td>
        <td>{item.name}</td>
        <td>{type}</td>
        <td>
          {
            (item?.customer_status_group_id && statusGroupsList) ?
              statusGroupsList.find(i => i.value === item.customer_status_group_id)?.label : ''
          }
        </td>
        <td>{prettyDate(item.created_at)}</td>
        {(canEditTeam || canDeleteTeam || canEditDepartment || canDeleteDepartment) &&
          <td>
            <div className="hstack gap-3 flex-wrap">
              {(canEditTeam && type === 'Team') &&
                <Link
                  to="#"
                  id={`edit_team_button_${idx + 1}`}
                  className="link-success fs-15"
                  onClick={() => {
                    editDepartmentAndTeamHandler((team_id || department_id || 0), type, item.name)
                  }}
                >
                  <i className="ri-edit-2-line"></i>
                </Link>}
              {(canEditDepartment && type === 'Department') &&
                <Link
                  to="#"
                  id={`edit_department_button_${idx + 1}`}
                  className="link-success fs-15"
                  onClick={() => {
                    editDepartmentAndTeamHandler((team_id || department_id || 0), type, item.name)
                  }}
                >
                  <i className="ri-edit-2-line"></i>
                </Link>}
              {(canDeleteDepartment && type === 'Department') &&
                <Link
                  to="#"
                  id={`delete_department_button_${idx + 1}`}
                  onClick={() => {
                    deleteDepartmentAndTeamHandler((team_id || department_id || 0), type, item.name)
                  }}
                  className="link-danger fs-15"
                >
                  <i className="ri-delete-bin-line"></i>
                </Link>}
              {(canDeleteTeam && type === 'Team') &&
                <Link
                  to="#"
                  id={`delete_team_button_${idx + 1}`}
                  onClick={() => {
                    deleteDepartmentAndTeamHandler((team_id || department_id || 0), type, item.name)
                  }}
                  className="link-danger fs-15"
                >
                  <i className="ri-delete-bin-line"></i>
                </Link>}
            </div>
          </td>}
      </tr>
    )
  }

  useEffect(() => {
    getDepartmentsData()
    getCustomerStatusGroupsData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-end mt-1 mb-3">
      {canCreateDepartment &&
        <Button color="primary" id="add_department_button" className="btn mx-3" onClick={addDepartmentHandler}>
          Add Department
        </Button>}
        {canCreateTeam &&
        <Button color="primary" id="add_team_button" className="btn" onClick={addTeamHeaderHandler}>
          Add Team
        </Button>}
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-responsive table-nowrap">
          <thead className="border-bottom">
          <tr>
            <th className="col-1" scope="col">ID</th>
            <th className="col-2" scope="col">NAME</th>
            <th className="col-2" scope="col">TYPE</th>
            <th className="col-3" scope="col">STATUS GROUP</th>
            <th className="col-3" scope="col">CREATED AT</th>
            {(canEditTeam || canDeleteTeam || canEditDepartment || canDeleteDepartment) &&
            <th className="col-1" scope="col">ACTIONS</th>}
          </tr>
          </thead>
          <tbody>
          {departments.length ?
            departments.map((department, idx) => {
              return (
                <React.Fragment key={idx}>
                  {rowTemplate(department, department.id)}
                  {(department.teams.length > 0 && canViewTeams) &&
                    department.teams.map((team, idx) => {
                      return (
                        <React.Fragment key={team.id}>
                          {rowTemplate(team, department.id, team.id, idx)}
                        </React.Fragment>
                      )
                    })}
                </React.Fragment>
              )
            }) :
            (<tr>
              <td colSpan={5} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default DepartmentsAndTeams
