import React, {useContext, useEffect, useState} from 'react'
import {Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Row} from "reactstrap"
import {ModalContext} from "../../../../contexts"
import {updateSecurity} from "../../../../api/securities"
import {toast} from "react-toastify"
import {selectListData} from "../../../../api/general"
import Select from "react-select"
import {isFloatPattern, slugify} from "../../../../helpers/utils"
import {getAllAssets} from "../../../../api/transactions"
import {Link} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import * as iconsList from '../../../../assets/images/securities'
import _ from "lodash"

const EditSecurity = (props) => {
  const {data, updateTable} = props
  const {closeModal} = useContext(ModalContext)
  const [securityName, setSecurityName] = useState('')
  const [fieldsData, setFieldsData] = useState({})
  const [pagesOption, setPagesOption] = useState([])
  const [securityPage, setSecurityPage] = useState()
  const [newFieldLabel, setNewFieldLabel] = useState('')
  const [assetOptions, setAssetOptions] = useState([])

  // BEGIN Image dropdown options
  const [isMouseOnOptions, setIsMouseOnOptions] = useState('')
  const [logoValue, setLogoValue] = useState('')
  const [isImgDropdownShow, setIsImgDropdownShow] = useState(false)
  const [imgDropdownOptions, setImgDropdownOptions] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (!_.isEmpty(iconsList)) {
      let options = Object.entries(iconsList).reduce((acc, item) => {
        item = {label: item[0], path: item[1]}
        return [...acc, item]
      }, [])

      setImgDropdownOptions(options.sort().reverse())
    }
  }, [iconsList])

  const filteredOptions = imgDropdownOptions.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  )
  // END Image dropdown options

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = {
      name: securityName,
      type: data.type,
      page_id: securityPage?.value || '',
      fields: fieldsData
    }

    updateSecurity(data.id, formData).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  const addNewFieldsHandler = (e) => {
    e.preventDefault()

    if (!newFieldLabel) return toast.info('Label is required field!')

    const key = slugify(newFieldLabel)

    if (Object.keys(fieldsData).includes(key)) {
      return toast.info('Such field already exists!')
    }

    const updatedFields = {
      ...fieldsData,
      [key]: {label: newFieldLabel, type: "text", is_custom: true}
    }

    const sortedFields = Object.fromEntries(
      Object.entries(updatedFields).sort(([, a], [, b]) => {
        if (a.type === 'textarea') return 1
        if (b.type === 'textarea') return -1
        return 0
      })
    )

    setFieldsData(sortedFields)
    setNewFieldLabel('')
  }

  const deleteCustomFieldHandler = (key) => {
    setFieldsData(prevMetaData => {
      const { [key]: _, ...rest } = prevMetaData
      return {...rest}
    })
  }

  const getAssets = () => {
    getAllAssets().then(r => {
      setAssetOptions(r.map(i => ({...i, label: i.label + ' / ' + i.code})))
    })
  }

  const changeMetaValuesHandler = (value, key) => {
    setFieldsData(prevMetaData => ({
      ...prevMetaData,
      [key]: {...prevMetaData[key], value: value}
    }))
  }

  const changePayoutAssetCodeHandler = (code, key) => {
    if (fieldsData && fieldsData['payout_asset']) {
      setFieldsData(prevMetaData => ({
        ...prevMetaData,
        [key]: {...prevMetaData[key], payout_asset_code: code}
      }))
    }
  }

  useEffect(() => {
    if (data) {
      setSecurityName(data.name)
      setFieldsData(data.fields)
    }
  }, [data])

  useEffect(() => {
    selectListData('pages_list').then(r => {
      setPagesOption(r)
    })
  }, [])

  useEffect(() => {
    if (data.page_id) {
      setSecurityPage(pagesOption.find(i => i.value === data.page_id))
    }
  }, [pagesOption, data])

  useEffect(() => {
    if (fieldsData.hasOwnProperty('payout_asset') && !assetOptions?.length) {
      getAssets()
    }
  }, [fieldsData])

  useEffect(() => {
    if (!_.isEmpty(fieldsData)) {
      setLogoValue(fieldsData.logo?.value)
    }
  }, [fieldsData])

  return (
    <>
      <Form onSubmit={e => submitHandler(e)}>
        <Row>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              name="name"
              required
              placeholder="Name"
              autoComplete="off"
              id="edit_security_name"
              value={securityName}
              onChange={e => setSecurityName(e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Page</Label>
            <Select
              placeholder="Choose..."
              isClearable={true}
              name="page_id"
              id="edit_security_page_id"
              value={securityPage}
              onChange={selected => setSecurityPage(selected)}
              options={pagesOption}
            />
          </div>

          {Object.values(fieldsData).length ?
            Object.entries(fieldsData).map(([key, values], idx) => {

              const isPopular = key === 'popular'
              const popularMessage = 'Check the box to display it in the popular list'
              let options = []

              if (values.type === 'select' && values.options) {
                options = Object.entries(values.options).map(([key, value]) => ({
                  value: key,
                  label: value
                }))
              }

              const fieldName = key || `field_${idx}`

              return (
                <Col
                  className={`form-group mb-2 ${values.type === 'textarea' ? 'col-12' : 'col-12 col-lg-6'}
                    ${values.type === 'checkbox' ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`
                  }
                  key={idx}
                >
                  <div className="d-flex align-items-end position-relative">
                    <div className={`w-100 ${values.type === 'checkbox' ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`}>
                      <Label
                        className={`
                          custom-label
                          ${values.type === 'checkbox' ? 'pb-1' : ''}
                          ${String(key) === 'logo' ? 'pe-4' : ''}
                        `}>
                        {values.label}
                        {isPopular && <span className="ms-1 fs-12 text-muted">({popularMessage})</span>}
                      </Label>
                      {values.type === 'select' ?
                        <Select
                          placeholder="Enter the value"
                          name={key || `field_${idx}`}
                          id={`edit_security_${fieldName}`}
                          value={key === 'payout_asset' ?
                            (assetOptions.find(i => i.value === Number(values.value)) || null) :
                            (options.find(i => i.value === values.value) || null)}
                          onChange={selected => {
                            changeMetaValuesHandler(selected.value, key)

                            if (key === 'payout_asset') {
                              changePayoutAssetCodeHandler(selected.code, key)
                            }
                          }}
                          options={key === 'payout_asset' ? assetOptions : options}
                          required={values.required}
                        />
                        :
                        <div className='position-relative d-flex align-items-center'>
                          <Input
                            pattern={isFloatPattern(key)}
                            placeholder="Enter the value"
                            name={key || `field_${idx}`}
                            id={`edit_security_${fieldName}`}
                            style={String(key) === 'logo' ? {paddingRight: '32px'} : {}}
                            type={values.type}
                            value={values.type === 'checkbox' ? undefined : (values.value || '')}
                            checked={values.type === 'checkbox' ? (values.value || false) : undefined}
                            onChange={e =>
                              changeMetaValuesHandler(values.type === 'checkbox' ? e.target.checked : e.target.value, key)
                            }
                            autoComplete='off'
                            required={values.required}
                          />

                          {/* BEGIN Special Logo field items */}
                          {String(key) === 'logo' ?
                            <>
                              {/* BEGIN Image dropdown button */}
                              <div
                                className="position-absolute"
                                style={{right: '5px', top: '8px'}}
                              >
                                <Link
                                  to='#'
                                  id={`edit_security_image_drp`}
                                  className="link-primary fs-24 d-flex align-items-center"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setIsImgDropdownShow(!isImgDropdownShow)
                                  }}
                                >
                                  <i className="bx bx-image-add p-0 fs-24"></i>
                                </Link>
                              </div>
                              {/* END Image dropdown button */}

                              {/* BEGIN Info icon for Logo field */}
                              <div className="position-absolute" style={{top: '-15px', left: '90px', zIndex: '2'}}>
                                <i
                                  className="ri-question-line align-middle fs-20 ms-1 text-primary"
                                  style={{cursor: 'help'}}
                                  data-tooltip-id={'img_helper'}
                                  data-tooltip-content={
                                    'Enter the image URL or select from the list'
                                  }
                                ></i>
                                <Tooltip id={'img_helper'} style={{maxWidth: '400px'}}/>
                              </div>
                              {/* END Info icon for Logo field */}
                            </>
                            : null
                          }
                          {/* END Special Logo field items */}
                        </div>
                      }
                    </div>
                    {values.is_custom ?
                      <button
                        type="button"
                        id={`edit_security_delete_custom_field_button`}
                        className="btn btn-danger px-3 btn-icon waves-effect waves-light ms-2"
                        onClick={() => deleteCustomFieldHandler(key)}
                      >
                        <i className="mdi mdi-archive-remove-outline fs-20"></i>
                      </button> : null
                    }

                    {/* BEGIN Options dropdown */}
                    {String(key) === 'logo' && isImgDropdownShow ?
                      <Card
                        className='position-absolute w-100 shadow-lg border'
                        style={{zIndex: '5', maxHeight: '300px', left: '0px', top: '55px'}}
                      >
                        <CardHeader className="p-1">
                          <div className="form-icon">
                            <Input
                              type="text"
                              className="form-control form-control-icon"
                              placeholder="Find..."
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <i className="ri-search-2-line"></i>
                          </div>
                        </CardHeader>
                        <CardBody className='overflow-scroll border-1 border-dark py-1 px-0 rounded-3 bg-card'>
                          <ul className='mb-0 px-0' style={{listStyleType: 'none'}}>
                            {!_.isEmpty(filteredOptions) ?
                              filteredOptions.map((item, idx) => {
                                const id = String(Number(idx) + 1)

                                return (
                                  <li
                                    key={idx}
                                    className={`
                                      py-2 px-2
                                      ${(isMouseOnOptions === id && logoValue !== item.path) ? 'bg-body' : ''}
                                      ${logoValue === item.path ? 'bg-secondary text-white' : ''}
                                    `}
                                    onMouseEnter={() => setIsMouseOnOptions(id)}
                                    onMouseLeave={() => setIsMouseOnOptions('')}
                                    onClick={() => {
                                      setIsImgDropdownShow(false)
                                      setLogoValue(item.path)
                                      changeMetaValuesHandler(item.path, 'logo')
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="avatar-xxs me-2 rounded-circle" style={{minWidth: '24px', minHeight: '24px'}}>
                                        <img
                                          src={item.path}
                                          className="img-fluid h-100 w-100 rounded-circle"
                                          alt='#'
                                        />
                                      </div>
                                      <a className="dropdown-item text-truncate" href="#">{item.label}</a>
                                    </div>
                                  </li>
                                )
                              }) : null
                            }
                          </ul>
                        </CardBody>
                      </Card> : null
                    }
                    {/* END Options dropdown */}
                  </div>
                </Col>
              )
            }) : null
          }

          {Object.keys(fieldsData).length ?
            <div className="d-flex align-items-center py-1 mt-2">
              <span className="text-muted">Add field</span>
              <hr className="p-0 m-0 flex-1 ms-2"/>
            </div> : null
          }
        </Row>

        {Object.keys(fieldsData).length ?
          <Row className="mt-2">
            <Col className="d-flex align-items-end">
              <div className="form-group w-100">
                <Label className='custom-label'>Label</Label>
                <Input
                  placeholder="Field label"
                  type="text"
                  id="edit_security_custom_field_label"
                  autoComplete='off'
                  value={newFieldLabel}
                  onChange={e => setNewFieldLabel(e.target.value)}
                />
              </div>
              <button
                type="button"
                id="edit_security_add_custom_field"
                className="btn btn-primary px-3 btn-icon waves-effect waves-light ms-4"
                onClick={e => addNewFieldsHandler(e)}
              >
                <i className="mdi mdi-layers-plus fs-20"></i>
              </button>
            </Col>

          </Row> : null
        }

        <div className="d-flex justify-content-between mt-3">
          <Button type="button" id="edit_security_cancel_button" color="warning" onClick={closeModal} className="btn">Cancel</Button>
          <Button type="submit" id="edit_security_submit_button" color="primary" className="btn">Submit</Button>
        </div>
      </Form>
    </>
  )
}

export default EditSecurity
