import React from "react"
import { Navigate } from "react-router-dom"

//login
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword"
import Register from "../pages/Authentication/Register"
//Dashboard
import DashboardCrypto from "../pages/DashboardCrypto"
//Transactions
import Transactions from "../pages/Transactions"
//Customers
import Customers from "../pages/Customers";
import {Profile} from "../pages/Customers/Profile";
//Invoices
import Invoices from "../pages/Invoices"
import Invoice from "../pages/Invoices/Invoice"
import InvoiceDetails from "../pages/Invoices/InvoiceDetails"
//Employees
import Employees from "../pages/Employees"
import EmployeeProfile from "../pages/Employees/Profile/Profile"
//Tickets
import Tickets from "../pages/Tickets"
import SingleTicket from "../pages/Tickets/SingleTicket"
// Calendar
import Calendar from "../pages/Calendar"
//Settings
import Settings from "../pages/Settings/Settings"
//Roles
import Roles from "../pages/Roles"
import TwoFA from "../pages/Authentication/2FA/TwoFA";
import PinCode from "../pages/Authentication/PinCode";
//Import
import Import from "../pages/Import"
// Notifications
import Notifications from "../pages/Notifications"
//Chat
import Chat from "../pages/Chat"
import Postbacks from "../pages/Postbacks/Postbacks"
import PostbacksHistory  from "../pages/Postbacks/History"
// Securities
import Pages from "../pages/Secutities/Pages";
import Securities from "../pages/Secutities/Securities";
// Analytics
import Analytics from "../pages/Analytics";
// Error
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardCrypto /> },
  { path: "/customers", component: <Customers /> },
  { path: "/customers/show/:id", component: <Profile /> },
  { path: "/transactions", component: <Transactions /> },
  { path: "/invoices", component: <Invoices /> },
  { path: "/invoices/create/:id", component: <Invoice /> },
  { path: "/invoices/show/:id", component: <InvoiceDetails /> },
  { path: "/employees", component: <Employees /> },
  { path: "/employees/show/:id", component: <EmployeeProfile /> },
  { path: "/tickets/list", component: <Tickets /> },
  { path: "/tickets/list/show/:id", component: <SingleTicket /> },
  { path: "/tickets/chats", component: <Chat />},
  { path: "/calendar", component: <Calendar /> },
  { path: "/settings", component: <Settings /> },
  { path: "/roles", component: <Roles /> },
  { path: "/import", component: <Import /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/postbacks/list", component: <Postbacks/> },
  { path: "/postbacks/history", component: <PostbacksHistory/> },
  { path: "/securities/pages", component: <Pages/> },
  { path: "/securities/list", component: <Securities/> },
  { path: "/analytics", component: <Analytics/> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
]

const publicRoutes = [
  // Error page
  { path: "/error", component: <Alt404 /> },
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/2fa", component: <TwoFA /> },
  { path: "/pin-code", component: <PinCode /> },
]

export { authProtectedRoutes, publicRoutes }
