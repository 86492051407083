import {Button, Col, Form, FormGroup, Input, Label} from "reactstrap"
import {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {createBankDetail} from "../../../api/settings"
import {toast} from "react-toastify"

const AddBankDetails = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [isFieldVisible, setIsFieldVisible] = useState(false)
  const [addedFields, setAddedFields] = useState([])

  const addFieldHandler = () => {
    if (!isFieldVisible) {
      setAddedFields([...addedFields, {key: '', value: ''}])
    }
  }

  const fieldsChangeHandler = (index, fieldName, value) => {
    const updatedFields = [...addedFields]
    updatedFields[index][fieldName] = value
    setAddedFields(updatedFields)
  }

  const removeFieldHandler = (index) => {
    const updatedFields = addedFields.filter((_, i) => i !== index)
    setAddedFields(updatedFields)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    data.fields = addedFields

    createBankDetail(data).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(error => {
            toast.error(error)
          })
        })
      }
    })
  }

  useEffect(() => {
    addFieldHandler()
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Bank Details Name</Label>
          <Input name="bank_details_name" id="bd_name_input" placeholder="Bank Details Name"/>
        </div>
        <div className="d-flex flex-column">
          {addedFields.map((field, idx) => (
            <div className="d-flex align-items-center justify-content-between" key={idx}>
              <Col className="col-3">
                <FormGroup>
                  <Input
                    value={field.key}
                    id={`bd_iban_input_${idx + 1}`}
                    onChange={e => fieldsChangeHandler(idx, 'key', e.target.value)}
                    placeholder="Ex. IBAN"
                  />
                </FormGroup>
              </Col>
              <Col className="col-7">
                <FormGroup>
                  <Input
                    value={field.value}
                    id={`bd_number_input_${idx + 1}`}
                    onChange={e => fieldsChangeHandler(idx, 'value', e.target.value)}
                    placeholder="GB29 NWBK 6016 1331 9268 19"
                  />
                </FormGroup>
              </Col>
              <div className="mb-3">
                <Button
                  type="button"
                  onClick={() => removeFieldHandler(idx)}
                  className="btn btn-danger"
                  id={`remove_field_button_${idx + 1}`}
                >
                  X
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div>
          <hr className="w-100 px-0 mt-0"/>
          <Button
            type="button"
            color="primary"
            onClick={addFieldHandler}
            className="btn mb-3"
            id="add_field_button"
          >
            Add Field
          </Button>
        </div>
        <div className="hstack justify-content-end">
          <Button
            type="button"
            id="close_bd_modal_button"
            color="warning"
            onClick={closeModal}
            className="btn me-3"
          >
            Close
          </Button>
          <Button type="submit" id="submit_bd_button" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddBankDetails
