import React, {useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {Link} from "react-router-dom"
import BaseTable from "../../../Components/Tables/BaseTable"
import {prettyDate} from "../../../helpers/utils"
import {capitalize} from "lodash"
import {deleteSecurity, disableOrEnabledSecurity} from "../../../api/securities"
import {toast} from "react-toastify"
import {ModalContext} from "../../../contexts"
import EditSecurity from "./Modals/EditSecurity"
import {useUserCan} from "../../../Components/Hooks/UserHooks"
import SecurityDetails from "../../Customers/Profile/Modals/SecurityDetails"
import {filtersList} from "../../../api/general"

const SecuritiesTable = ({methodsRef, withFilters, customerId = ''}) => {
  const baseTableMethodsRef = useRef(null)
  const {openModal} = useContext(ModalContext)
  const tableRowsPerPage = 'securities_rows_per_page'
  const [filters, setFilters] = useState([])
  const [securitiesLogoUrlValidList, setSecuritiesLogoUrlValidList] = useState([])
  let filterStorageKey = 'security_filter'

  // Permissions
  const canManageSecurities = useUserCan('manage_securities')

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  useImperativeHandle(methodsRef, () => ({
    updateTable: () => updateTable()
  }))

  const openEditModalHandler = (security) => {
    openModal({
      title: `Edit "${security.name}"`,
      content: <EditSecurity data={security} updateTable={updateTable}/>,
      size: 'lg'
    })
  }

  const openSecurityDetailsModal = (item) => {
    openModal({
      title: `"${item.name}" details`,
      content: <SecurityDetails item={item} updateTable={updateTable}/>,
    })
  }

  const deleteSecuritiesHandler = (id, name) => {
    if (confirm(`Do you really want to delete "${name}"?`)) {
      deleteSecurity(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const disableOrEnabledSecurityHandler = (row) => {
    if (confirm(`Do you really want to ${String(row.visible) === 'No' ? 'enabled' : 'disable'} "${row.name}"?`)) {
      disableOrEnabledSecurity(row.id).then(r => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>ID</span>,
        selector: row => (customerId ?
          <a href="#" onClick={() => openSecurityDetailsModal(row)}>#{row.id}</a>
          : <>#{row.id}</>
        ),
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "name",
        name: <span className='font-weight-bold fs-14'>Name</span>,
        selector: row =>
          (
            <div className="d-flex align-items-center">
              <div className="avatar-xxs me-2 rounded-circle" style={{minWidth: '24px'}}>
                {securitiesLogoUrlValidList.includes(String(row.id)) || !row.fields?.logo?.value ? (
                  <div className="avatar-title bg-soft-primary text-primary">
                    {capitalize(row.name.slice(0, 1))}
                  </div>
                ) : (
                  <img
                    src={row.fields?.logo?.value}
                    className="img-fluid h-100"
                    alt={row.name}
                    onError={() => setSecuritiesLogoUrlValidList(prevState => ([...prevState, String(row.id)]))}
                  />
                )}
              </div>
              <div className="text-truncate">{row.name}</div>
            </div>),
        omit: false
      },
      {
        id: "type",
        name: <span className='font-weight-bold fs-14'>Type</span>,
        selector: row => capitalize(row.type),
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "enabled",
        name: <span className='font-weight-bold fs-14'>Enabled</span>,
        selector: row => capitalize(row.visible),
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "created_at",
        name: <span className='font-weight-bold fs-14'>Created At</span>,
        selector: row => prettyDate(row.created_at),
        // sortable: true,
        // width: "110px",
        omit: false
      },
      {
        id: "actions",
        name: <span className='font-weight-bold fs-14'>Actions</span>,
        width: "110px",
        selector: row => (
          <>
          {canManageSecurities &&
              <div className="hstack gap-3 flex-wrap">
                {!customerId ?
                  <Link to="#" className="link-success fs-15" onClick={() => {
                    openEditModalHandler(row)
                  }}>
                    <i className="ri-edit-line id-edit-security-link"></i>
                  </Link> : null
                }
                <Link
                  to="#"
                  className={`link-${String(row.visible) === 'Yes' ? 'danger' : 'success'} fs-15`}
                  onClick={() => {disableOrEnabledSecurityHandler(row)}}
                >
                  <i className={`mdi ${String(row.visible) === 'Yes' ? 'mdi-eye-off' : 'mdi-eye-refresh'} id-delete-security-link`}></i>
                </Link>
            </div>}
          </>
        ),
        omit: false
      }
    ], [canManageSecurities, securitiesLogoUrlValidList])

  useEffect(() => {
    filtersList(filterStorageKey).then(r => {
      setFilters(r)
    })
  }, [])

  return (
    <BaseTable
      columns={columns}
      title="Securities"
      filterFields={withFilters ? filters : []}
      url="/spa/securities/data"
      selectedRowsHandler={() => {}}
      methodsRef={baseTableMethodsRef}
      tableRowsPerPage={tableRowsPerPage}
      filterStorageKey={filterStorageKey}
    />
  )
}

export default SecuritiesTable
