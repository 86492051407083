import React, {useContext, useEffect, useReducer} from 'react'
import { Button, Card, CardBody, Form, Input, Label } from "reactstrap"
import {toast} from "react-toastify"
import {ModalContext} from "../../../../contexts";
import {createPage} from "../../../../api/pages";

function tabsDataReducer(fields, action) {
  switch (action.type) {
    case 'addTab': {
      return [...fields, { uuid: action.uuid, id: action.id, title: action.title }]
    }

    case 'updateTab': {
      return fields.map((tab, idx) => idx === action.index ? { ...tab, title: action.title } : tab);
    }

    case 'removeTab': {
      return fields.filter((_, idx) => idx !== action.index)
    }

    default:
      return fields
  }
}

const initialTabsData = []

const AddPage = ({updateData}) => {
  const { closeModal } = useContext(ModalContext)
  const [tabsData, dispatchTabsData] = useReducer(tabsDataReducer, initialTabsData)

  const addTabHandler = () => {
    dispatchTabsData({
      type: 'addTab',
      uuid: Date.now(),
      id: `general_tab_${Date.now()}`
    })
  }

  const updateTabHandler = (index, title) => {
    dispatchTabsData({ type: 'updateTab', index, title })
  }

  const deleteTabHandler = (index) => {
    dispatchTabsData({ type: 'removeTab', index })
  }

  useEffect(() => {
    addTabHandler()
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    let newData = {
      page_title: data.page_title,
      slug: data.slug,
      visible: !!(data?.visible && data?.visible === 'on'),
      tabs: tabsData.filter(tab => tab.title)
    }

    createPage(newData).then(r => {
      if (r.success) {
        toast.success(r.message)
        closeModal()
        updateData()
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group">
          <Label className="form-label mb-0">Page title</Label>
          <Input
            type="text"
            name="page_title"
            placeholder="Page title"
            autoComplete="off"
            id="page_title"
            required
          />
        </div>

        <div className="form-group mt-2">
          <Label className="form-label mb-0">Slug</Label>
          <Input
            type="text"
            name="slug"
            placeholder="Slug"
            autoComplete="off"
            id="page_slug"
            required
          />
        </div>

        <Card className="border-top-0 mt-1 p-0">
          <CardBody className="px-2 pb-2">
            {tabsData && tabsData.length > 0 && tabsData.map((tab, idx) => (
              <React.Fragment key={tab.uuid}>
                <Label className="form-label mb-0 mt-1">Tab name:</Label>
                <div className="d-flex align-items-center">
                  <div className="form-group w-100">
                    <Input
                      type="text"
                      name="tab_title"
                      placeholder="Tab name"
                      autoComplete="off"
                      id={`new_tab_${idx + 1}_name`}
                      value={tab.title || ''}
                      onChange={(e) => updateTabHandler(idx, e.target.value)}
                    />
                  </div>

                  <button
                    type="button"
                    id={`${idx === tabsData.length - 1 ? 'add_new_tab' : `delete_tab_${idx + 1}`}`}
                    className={`btn btn-${idx === tabsData.length - 1 ? 'primary' : 'danger'} px-3 btn-icon waves-effect waves-light ms-3`}
                    onClick={() => idx === tabsData.length - 1 ? addTabHandler() : deleteTabHandler(idx)}
                  >
                    {idx === tabsData.length - 1 ?
                      <i className="mdi mdi-layers-plus fs-20"></i> :
                      <i className="mdi mdi-archive-remove-outline fs-20"></i>
                    }
                  </button>
                </div>
              </React.Fragment>
            ))}
          </CardBody>
        </Card>

        <div className="form-check form-switch form-switch-left form-switch-sm mt-3 mb-3">
          <Input
            className="form-check-input"
            name="visible"
            type="checkbox"
            id="is_page_visible"
            defaultChecked
          />
          <Label className="form-check-label text-muted" for="hidden_from_customer">
            Page visible
          </Label>
        </div>

        <div className="mt-3 d-flex justify-content-end">
          <Button type="button" id="add_page_cancel_button" className="btn btn-warning me-3 py-1" onClick={closeModal}>Cancel</Button>
          <Button type="submit" id="add_page_submit_button" className="btn btn-success py-1">Submit</Button>
        </div>
      </Form>
    </>
  )
}

export default AddPage
