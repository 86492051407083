import {ModalContext} from "../../../contexts"
import React, {useContext, useEffect, useState} from "react"
import {Button, Form, Input, Label} from "reactstrap"
import Select from "react-select"
import {updateConfig, getConfiguration} from "../../../api/settings"
import {toast} from "react-toastify"

const AddEmailConfig = ({id, updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [host, setHost] = useState()
  const [port, setPort] = useState()
  const [domain, setDomain] = useState()
  const [userName, setUserName] = useState()
  const [name, setName] = useState()
  const [transport, setTransport] = useState()
  const [encryption, setEncryption] = useState()
  const [password, setPassword] = useState()
  const [selectedTransportOption, setSelectedTransportOption] = useState()
  const [selectedEncryptionOption, setSelectedEncryptionOption] = useState()
  const [transportOptions, setTransportOptions] = useState([
    {value: 'smtp', label: 'SMTP'},
  ])
  const [encryptionOptions, setEncryptionOptions] = useState([
    {value: 'tsl', label: 'TSL'},
  ])

  const getConfigurationData = () => {
    getConfiguration(id).then(r => {
      if (r.success) {
        let config = r.configurations
        setHost(config.data.host)
        setPort(config.data.port)
        setDomain(config.data.domain)
        setUserName(config.data.username)
        setName(config.name)
        setTransport(config.data.transport)
        setEncryption(config.data.encryption)
        setPassword(atob(config.data.password))
      } else {
        toast.error(r.message)
      }
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let data = new FormData(e.target)
    data.append('configuration_id', id)

    updateConfig(data).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  useEffect(() => {
    getConfigurationData()
  }, [])

  useEffect(() => {
    if (encryption !== '') {
      setSelectedEncryptionOption(encryptionOptions.find(option => option.value === encryption))
    }

    if (transport !== '') {
      setSelectedTransportOption(transportOptions.find(option => option.value === transport))
    }
  }, [encryption, transport])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Input name="type" value="email" type="hidden" />
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Config name</Label>
          <Input
              id="edit_email_config_name"
              name="name"
              value={name ?? ''}
              onChange={(e) => setName(e.target.value)}
              placeholder="Config name"
              required={true}
              autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Transport</Label>
          <Select
            id="edit_email_config_transport"
            name="transport"
            placeholder="Select..."
            value={selectedTransportOption}
            onChange={(selected) => setSelectedTransportOption(selected)}
            options={transportOptions}
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Encryption</Label>
          <Select
            id="edit_email_config_encrypt"
            name="encryption"
            value={selectedEncryptionOption}
            onChange={(selected) => setSelectedEncryptionOption(selected)}
            placeholder="Select..."
            options={encryptionOptions}
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Domain</Label>
          <Input
            id="edit_email_config_domain"
            name="domain"
            value={domain ?? ''}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="Domain"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Host</Label>
          <Input
            id="edit_email_config_host"
            name="host"
            value={host ?? ''}
            onChange={(e) => setHost(e.target.value)}
            placeholder="Host"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Port</Label>
          <Input
            id="edit_email_config_port"
            name="port"
            value={port ?? ''}
            onChange={(e) => setPort(e.target.value)}
            placeholder="Port"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">User Name</Label>
          <Input
            id="edit_email_config_username"
            name="username"
            value={userName ?? ''}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Host"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Password</Label>
          <Input
            id="edit_email_config_pass"
            name="password"
            value={password ?? ''}
            onChange={e => setPassword(e.target.value)}
            placeholder="Enter a new password"
            autoComplete="off"
          />
        </div>

        <div className="hstack justify-content-end">
          <Button type="button" id="edit_email_config_close_modal_button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" id="edit_email_config_save_button" color="primary" className="btn">Save</Button>
        </div>

      </Form>
    </>
  )
}

export default AddEmailConfig
