import {Button, Table} from "reactstrap"
import {Link} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {deleteBankDetail, getBankDetails} from "../../api/settings"
import {toast} from "react-toastify"
import AddBankDetails from "./Modals/AddBankDetails"
import {ModalContext} from "../../contexts"
import EditBankDetail from "./Modals/EditBankDetail"
import {useUserCan} from "../../Components/Hooks/UserHooks"

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState([])
  const {openModal} = useContext(ModalContext)

  // Permissions
  const canAddBankDetails = useUserCan('create_bank_details')
  const canEditBankDetails = useUserCan('edit_bank_details')
  const canDeleteBankDetails = useUserCan('delete_bank_details')

  const getBankDetailsData = () => {
    getBankDetails().then((r) => {
      setBankDetails(r)
    })
  }

  const updateBankDetail = (id, newName) => {
    const updatedBankDetails = bankDetails.map((item) => {
      if (item.id === id) {
        return {...item, name: newName}
      }
      return item
    })
    setBankDetails(updatedBankDetails)
    getBankDetailsData()
  }

  const addBankDetailsHandler = () => {
    openModal({
      title: "Add Bank Details",
      content: <AddBankDetails updateTable={getBankDetailsData}/>
    })
  }

  const deleteBankDetailsHandler = (id) => {
    if (confirm('Are you sure to delete Bank Detail?')) {
      deleteBankDetail(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getBankDetailsData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const editBankDetailsHandler = (name, id, fields) => {
    openModal({
      title: "Edit Bank Details",
      content: <EditBankDetail name={name} id={id} fields={fields} updateTable={updateBankDetail}/>
    })
  }

  useEffect(() => {
    getBankDetailsData()
  }, []);

  return (
    <>
      <div className="d-flex justify-content-end mt-1">
        {canAddBankDetails &&
        <Button color="primary" id="add_bank_details_button" className="btn mb-3" onClick={addBankDetailsHandler}>
          Add Bank Details
        </Button>}
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-nowrap">
          <thead>
          <tr className="border-bottom">
            <th className="col-1" scope="col">ID</th>
            <th className="col-10" scope="col">BANK DETAIL NAME</th>
            <th className="col-1" scope="col">ACTIONS</th>
          </tr>
          </thead>
          <tbody>
          {bankDetails.length ?
            bankDetails.map((bankDetail, idx) => {
              return (
                <tr className="border-bottom" key={idx}>
                  <td>{bankDetail.id}</td>
                  <td>{bankDetail.name}</td>
                  {(canEditBankDetails || canDeleteBankDetails) &&
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {canEditBankDetails &&
                      <Link
                        to="#"
                        id={`edit_bank_details_${bankDetail.id}`}
                        onClick={() =>
                          editBankDetailsHandler(bankDetail.name, bankDetail.id, bankDetail.fields.credentials)}
                        className="link-success fs-15"
                      >
                        <i className="ri-edit-2-line"></i>
                      </Link>}
                      {canDeleteBankDetails &&
                      <Link
                        to="#"
                        id={`delete_bank_details_${bankDetail.id}`}
                        onClick={() => deleteBankDetailsHandler(bankDetail.id)}
                        className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>}
                    </div>
                  </td>}
                </tr>
              )
            }) :
            (<tr>
              <td colSpan={3} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default BankDetails
